<template>
  <v-row v-scroll="onScroll">
   <!-- header -->
   <mini-header :key="resetTab" :breadcrumbs="breadcrumbs" :hiddenTab="true" :scroll="scroll">
     <template v-slot:main v-if="$helpers.hasPermission(['view_purchase'])">
      <v-btn class="mr-2" @click="getList" :disabled="!count" outlined>
        <v-img contain :src="require(`@/assets/icon-update${$vuetify.theme.dark ? '-dark' : ''}.svg`)" />
      </v-btn>
      <VMainColumnRecordVisualization :headers.sync="headersAux" :pageSize.sync="pageSize" object="purchases" />
       <v-menu close-on-click close-on-content-click offset-y left :nudge-bottom="10" transition="slide-y-transition">
        <template v-slot:activator="{ on, attrs }">
          <v-btn class="ml-2" outlined v-bind="attrs" v-on="on">
            <v-icon left>mdi-plus-box </v-icon>Opciones<v-icon size="18" right>mdi-chevron-down</v-icon>
          </v-btn>
        </template>
        <v-list class="pa-0">
          <v-list-item class="px-3" @click="createDownloadList" :disabled="!count" :ripple="false">
            <v-list-item-title class="body-1 grey-500--text">Descargar listado</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
       <v-query-builder class="ml-2" :query-map="filters" model="purchases" :active.sync="activeFilter" />
     </template>
     <template v-slot:custom-tab v-if="$helpers.hasSomePermission(['view_document'])">
        <v-row class="mt-14 ml-n6 mr-5" no-gutters align="center" :style="`postion: absolute; border-bottom: 1px solid var(--${$vuetify.theme.dark ? 'dark' : 'light'}-grey-50)`">
          <v-col cols="7" class="pa-0 background">
            <span class="subtitle-2 grey-700--text font-weight-medium">Estado comercial</span>
            <v-tabs v-model="filter" class="background pa-0 tab-rec" :height="35">
              <v-tab v-for="(status, i) in currentStatus.slice(0, 4)" :key="i" :ripple="false">
                <v-icon left small>mdi-{{ status.icon }}</v-icon>{{ status.title }}
                <template v-if="status.title === 'Todas'"></template>
              </v-tab>
            </v-tabs>
          </v-col>
          <v-spacer />
          <v-col cols="3" class="pa-0 text-right">
            <span class="subtitle-2 ml-n2 grey-700--text font-weight-medium">Estado {{ $t('generals.SUNAT') }}</span>
            <v-tabs v-model="sunatFilter" class="background mr-0 tab-rec" :height="35" :right="true" :key="$store.state.base.isExpandNavigationDrawer">
              <v-tab v-for="status in currentStatusSunat" :key="status.id" :ripple="false">
                <v-icon left small>mdi-{{ status.icon }}</v-icon>{{ status.title }}
              </v-tab>
            </v-tabs>
          </v-col>
        </v-row>
      </template>
   </mini-header>
   <!-- end header -->
   <v-col cols="12" class="px-0 mt-25">
    <purchase-list
      @activeBtnFilter="activeFilter = true"
      :selectedDocuments.sync="selectedDocuments"
      :headerList="headerList"
      :pageSize="pageSize"
       @getList="getList"
    />
   </v-col>

   <!-- dialog export -->
   <v-dialog v-model="dialogExport" width="700" persistent no-click-animation overlay-color="grey-500">
     <v-card>
       <v-card-title class="pa-0">
         <v-toolbar class="transparent" flat height="46">
          <v-toolbar-title class="px-5 subtitle-2 grey-500--text font-weight-semibold">Descarga de documentos recibidos</v-toolbar-title>
           <v-spacer />
           <v-toolbar-items>
             <v-row align="center" justify="center" class="mr-3">
               <v-btn @click="dialogExport=false" icon small retain-focus-on-click><v-icon color="grey-500" size="20">mdi-close</v-icon></v-btn>
             </v-row>
           </v-toolbar-items>
         </v-toolbar>
       </v-card-title>
       <v-divider />
       <v-card-text class="px-5 py-2">
          <v-list class="pa-0 transparent">
            <!-- <v-list-item>
              <v-list-item-content class="pb-0">
                <v-list-item-title class="grey-500--text body-1 font-weight-bold">Resumen de la exportación</v-list-item-title>
              </v-list-item-content>
            </v-list-item> -->
            <v-list-item class="mt-2">
              <v-list-item-content class="pt-0">
                <v-list-item-title class="grey-500--text body-1 font-weight-bold">Selecciona el formato de los archivos:</v-list-item-title>
                <v-list-item-subtitle class="pt-2">
                  <v-row class="ml-n1" no-gutters>
                    <v-col cols="6" class="pa-0" v-for="file in fileFormat" :key="file">
                      <v-checkbox class="ma-0" @click="getFileFormat(file)" :input-value="types.includes(file) || false" column :label="file" :disabled="['XLSX', 'CSV'].includes(file) && count >= 1000000" dense hide-details />
                    </v-col>
                  </v-row>
                </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </v-list>
          <!-- alert -->
          <v-alert class="inf-alert-blue blue-500--text rounded-md mt-2" :height="58" dense text>
            <div class="d-flex pa-0 my-1">
              <v-icon color="blue-500" size="22">mdi-information-outline</v-icon>
              <div class="ml-4">
                <span class="caption" style="line-height: 14px;">Esta acción
                  <span class="font-weight-bold">podría tardar varios minutos</span> en completarse. Recibirás un
                  <span class="font-weight-bold"> correo electrónico con el enlace de descarga.</span>
                </span>
              </div>
            </div>
          </v-alert>
          <!-- end alert -->
        </v-card-text>
        <v-divider />
        <v-card-actions class="py-2 px-5">
          <v-spacer/>
          <v-btn outlined @click="dialogExport=false" :ripple="false">Cancelar</v-btn>
          <v-btn color="blue-500" @click="createExport" :ripple="false" :disabled="!types.length">Descargar</v-btn>
        </v-card-actions>
     </v-card>
   </v-dialog>
   <!-- end dialog export -->
 </v-row>
</template>
<script>
import { mapState } from 'vuex'
import { saveAs } from 'file-saver'
import { groupBy, isEqual } from 'lodash'
import MiniHeader from '@/components/commons/MiniHeader'
import PurchaseList from '@/modules/purchase/components/PurchaseList'
import VQueryBuilder from '@/components/VQueryBuilder/VQueryBuilder'
import ListViewMixin from '@/mixins/ListViewMixin'
import DocumentTypes from '@/collections/documentTypes'
import PurchaseStatuses from '@/collections/purchaseStatuses'
import VMainColumnRecordVisualization from '@/components/commons/VMainColumnRecordVisualization'

export default {
 components: {
   PurchaseList,
   VQueryBuilder,
   MiniHeader,
   VMainColumnRecordVisualization
 },
 mixins: [
   ListViewMixin
 ],
  data: () => ({
  resetTab: 0,
  pageSize: null,
  headerList: [],
  activeFilter: false,
   scroll: 0,
   filter: 'Todos',
   sunatFilter: 0,
   status: [
    {
      status: [],
      title: 'Todas',
      icon: 'ballot',
      countries: ['PE', 'CL']
      // width: 'auto'
    },
     {
      title: 'Pendiente',
      icon: 'clock',
      countries: ['PE', 'CL']
    },
    {
      title: 'Aceptado',
      icon: 'check',
      countries: ['PE', 'CL']
    },
    {
      title: 'Rechazado',
      icon: 'close',
      countries: ['PE']
     },
     {
      title: 'Reclamado',
      icon: 'close',
      countries: ['CL']
     },
     {
      title: 'Aceptados',
      icon: 'bank-check',
      countries: ['PE', 'CL']
    },
    {
      title: 'Otros',
      icon: 'bank',
      countries: ['PE', 'CL']
    }
   ],
   dialogExport: false,
   types: [],
   documentTypes: DocumentTypes,
   purchaseStatuses: PurchaseStatuses,
   filters: [
     {
       type: 'text',
       id: 'supplier_name__text',
       label: 'Proveedor',
       value: null,
       category: 'a',
       title: 'Información del proveedor',
       operators: [],
       placeholder: 'Escribe el proveedor',
       countries: ['PE', 'CL']
     },
     {
       type: 'text',
       id: 'tax_id',
       label: 'RUT',
       value: null,
       category: 'a',
       operators: [],
       title: 'Información del proveedor',
       placeholder: 'Escribe el RUT',
       countries: ['PE', 'CL']
     },
     {
       type: 'date',
       id: 'date',
       label: 'Fecha doc.',
       value: null,
       value2: null,
       category: 'b',
       title: 'Fechas',
        operator: 'rango',
        operators: ['es igual', 'es menor a', 'es mayor a', 'rango'],
        countries: ['PE', 'CL']
     },
     {
       type: 'date',
       id: 'created',
       label: 'Fecha de crea.',
       value: null,
       value2: null,
       category: 'b',
       operator: 'rango',
       title: 'Fechas',
        operators: ['es igual', 'es menor a', 'es mayor a', 'rango'],
        countries: ['PE', 'CL']
     },
     {
       type: 'date',
       id: 'validation_date',
       label: 'FechaSUNAT',
       value: null,
       value2: null,
       category: 'b',
       operator: 'rango',
       title: 'Fechas',
      operators: ['es igual', 'es menor a', 'es mayor a', 'rango'],
      countries: ['PE', 'CL']
     },
     {
       type: 'text',
       id: 'id',
       label: 'ID',
       value: null,
       value2: null,
       category: 'c',
       title: 'Información del documento',
       placeholder: 'Escribe el ID',
       countries: ['PE', 'CL']
     },
     {
       type: 'select',
       isMultiple: true,
       id: 'type',
       value: null,
       label: 'Tipo de documento',
       category: 'c',
       choices: [],
       title: 'Información del documento',
       placeholder: 'Selecciona el tipo de documento',
       countries: ['PE', 'CL']
     },
     {
      type: 'text',
      id: 'series',
      label: 'Serie',
      value: null,
      category: 'c',
       title: 'Información del documento',
       placeholder: 'Escribe la serie',
       countries: ['PE']
    },
    {
      type: 'numeric',
      id: 'serial',
      label: 'Correlativo',
      value: null,
      value2: null,
      category: 'c',
      operator: 'rango',
      operators: ['es igual', 'es menor a', 'es mayor a', 'rango'],
      title: 'Información del documento',
      appendIcon: 'mdi-pound',
      countries: ['PE', 'CL']
    },
    {
      type: 'numeric',
      id: 'amount_total',
      label: 'Total',
      value: null,
      value2: null,
      category: 'c',
      operator: 'rango',
      title: 'Información del documento',
      operators: ['es igual', 'es menor a', 'es mayor a', 'rango'],
      appendIcon: 'mdi-currency-usd',
      countries: ['PE', 'CL']
    },
    {
      type: 'text',
      id: 'order_reference',
      label: 'Orden de compra',
      value: null,
      category: 'c',
      operators: [''],
      title: 'Información del documento',
      placeholder: 'Escribe la orden de compra',
      countries: ['PE', 'CL']
    }
   ],
   breadcrumbs: {
     main: 'Recepción',
     children: [
       {
         text: 'Documentos'
       }
     ]
   },
   fileFormat: ['XLSX', 'CSV', 'XML'], // 'PDF',
   selectedDocuments: [],
   headers: [
      { text: 'Proveedor', value: 'supplier_name', sortable: false, show: true, disabled: false, label: 'Proveedor', fixed: true },
      { text: 'Tipo de documento', value: 'type', sortable: true, show: true, disabled: false, label: 'Tipo de documento', fixed: true },
      { text: 'Serie-Correlativo', value: 'number', align: 'end', sortable: true, show: true, disabled: false, label: 'Folio #', fixed: true },
      { text: 'Fecha doc.', value: 'date', align: 'end', sortable: true, show: true, disabled: false, label: 'Fecha doc.', fixed: true },
      { text: 'Fecha de crea.', value: 'created', align: 'end', sortable: true, show: false, disabled: false, label: 'Fecha de creación', fixed: false },
      { text: 'Fecha val. SUNAT', value: 'validation_date', width: 150, sortable: true, show: false, disabled: false, label: 'Fecha val. SUNAT', fixed: false },
      { text: 'Estado comercial', value: 'rec_status', width: 140, show: true, disabled: false, label: 'Estado comercial', fixed: true },
      { text: 'Estado SUNAT', value: 'status', width: 140, show: true, disabled: false, label: 'Estado SUNAT', fixed: true },
      { text: 'Total', value: 'amount_total', align: 'end', sortable: true, show: true, disabled: false, label: 'Total', fixed: true },
      { text: '', value: 'actions', align: 'end', width: 16, show: true}
    ],
    headersAux: []
 }),
 computed: {
   ...mapState({
     count: state => state.purchases.recdocsList.length
   }),
   statusTab () {
     return Object.entries(groupBy(this.purchaseStatuses.filter(({countries}) => countries.includes(this.$store.state?.auth?.account?.country)), 'summary')).map(([key, value]) => {
       return { key, count: value.length, children: value }
     })
   },
   currentStatus () {
    return this.status.filter(({countries}) => countries.includes(this.$store.state?.auth?.account?.country)).slice(0, 4)
   },
   currentStatusSunat () {
    return this.status.filter(({countries}) => countries.includes(this.$store.state?.auth?.account?.country)).slice(4, 6)
   }
 },
 watch: {
  pageSize: {
    handler (val) {
      let currentQuery = JSON.parse(JSON.stringify(this.$route.query))
      if (currentQuery?._name) delete currentQuery._name
      if (val && currentQuery.page) {
        delete currentQuery.page
        this.$router.replace({name: this.$route.name, params: this.$route.params, query: currentQuery }).catch(() => {})
      } else if (Object.keys(currentQuery)?.length) this.getList()
    },
    inmediate: true
  },
   filter: {
    handler (val) {
      let currentQuery = JSON.parse(JSON.stringify(this.$route.query))
      if (val > 0) {
        delete currentQuery.page
          let query = this.purchaseStatuses.filter((item) => item.summary === this.currentStatus[val]?.title).map((item) => item.id).toString()
          this.$router.replace({name: this.$route.name, params: this.$route.params, query: {...currentQuery, rec_status: query, approved: this.$route.query.approved ?? 'true'} }).catch(() => {})
        } else {
          delete currentQuery.rec_status
          this.$router.replace({name: this.$route.name, params: this.$route.params, query: currentQuery }).catch(() => {})
      }
    },
    inmediate: false
   },
   $route () {
      if (!this.$route.query?.rec_status) {
        this.filter = 0
        this.resetTab += 1
     }

     if (!this.$route.query?._name) {
        this.breadcrumbs = {
          main: 'Recepción',
          children: [
            {
              text: 'Documentos'
            }
          ]
        }
      }
    },
   sunatFilter: {
     handler (val) {
      let currentQuery = JSON.parse(JSON.stringify(this.$route.query))
      if (val === 0) {
        delete currentQuery.page
          this.$router.replace({name: this.$route.name, params: this.$route.params, query: {...currentQuery, approved: 'true'} }).catch(() => {})
      } else {
        this.$router.replace({name: this.$route.name, params: this.$route.params, query: {...currentQuery, approved: 'false'} }).catch(() => {})
      }
    },
    inmediate: false
   },
   headersAux: {
    handler (val) {
      this.headerList = val.filter(({show}) => show)
    },
    inmediate: true
  }
 },
  created () {
    this.filters = this.filters?.filter(({countries}) => countries?.some((c) => c === this.$store.state?.auth?.account?.country))
    if (this.$route.query.rec_status) {
      this.resetTab += 1
      this.statusTab.forEach((item) => {
        const statusList = item.children.map((child) => child.id?.toString())
        const query = this.$route.query.rec_status.split(',')
        if (isEqual(statusList, query)) {
          const index = this.status.findIndex(({title}) => title === item.key)
          this.filter = index
        }
      })
    }
    if (this.$route.query.approved) this.sunatFilter = this.$route.query.approved === 'true' ? 0 : 1
    if ((Object.keys(this.$route?.query)?.length)) {
      this.getList()
    }
   this.setFilterDocumentType()

    this.headersAux = [...this.headers]

    if (this.$route.query._name) {
        this.breadcrumbs.children.push({
        text1: this.$route.query._name,
        route1: { name: 'PurchaseRetrieve', params: { id: this.$route.query._name, ...this.$route.params } }
      })
    }
 },
 methods: {
  getFileFormat (val = null) {
    if (!this.types?.find((s) => s === val)) this.types.push(val)
    else {
      this.types.splice(this.types.indexOf(val), 1)
      this.types = [...this.types]
    }
  },
   onScroll (e) {
     this.scroll = e.target?.scrollingElement?.scrollTop ?? 0
   },
   setFilterDocumentType () {
     const currentDocumentType = this.documentTypes.filter(({country}) => country === this.$store.getters['base/currentCountry'])
     if (currentDocumentType.length) {
       const index = this.filters.findIndex(({id}) => id === 'type')
       this.filters[index].choices = currentDocumentType.map(({name, id}) => {
         return {
           label: name,
           value: id
         }
       })
     }
   },
   getList () {
    if (this.$helpers.hasPermission(['view_purchase']) && Object.keys(this.$route?.query)?.length > 0) {
     let currentQuery = JSON.parse(JSON.stringify(this.$route.query))
      if (currentQuery?.ref) delete currentQuery.ref
      if (currentQuery?._name) delete currentQuery._name
     this.$store.dispatch('purchases/LIST', {
       resource: 'rec-docs',
       query: { ...currentQuery, ...(this.pageSize && { page_size: this.pageSize }) }
     })
    } else {
      this.$store.commit('purchases/SET_LIST', { response: {}, resource: 'rec-docs' })
    }
   },
   str2bytes (str) {
     var bytes = new Uint8Array(str.length)
     for (var i = 0; i < str.length; i++) {
       bytes[i] = str.charCodeAt(i)
     }
     return bytes
   },
   createExport () {
     this.dialogExport = false
     this.loading = true
     let currentQuery = JSON.parse(JSON.stringify(this.$route.query))
      if (currentQuery?._name) delete currentQuery._name
     this.$store.dispatch('accounts/LIST', {
       resource: 'exports/rec-docs',
       query: {
          ...currentQuery,
          extension: this.types.toString(),
          ...(this.selectedDocuments.length && {id__in: this.selectedDocuments.map(({id}) => id).toString()})
        },
       loader: true
     })
     .then(() => {
       this.$dialog.message.info('Se ha iniciado el proceso de descarga y se le notificará vía mail, una vez que haya finalizado', { timeout: 10000 })
     })
     .catch(() => {
       this.$dialog.message.error('Ha ocurrido un error en el proceso de descarga')
     })
     .finally(() => {
       this.loading = false
       this.types = []
     })
   },
   createDownloadList () {
    let currentQuery = JSON.parse(JSON.stringify(this.$route.query))
    if (currentQuery.page) delete currentQuery.page
    if (currentQuery.pageSize) delete currentQuery.pageSize
    if (currentQuery?._name) delete currentQuery._name
    this.$store.dispatch('accounts/LIST', {
      resource: 'rec-docs',
      query: {...currentQuery, ...{ format: 'CSV' }},
      responseType: 'blob'
    })
    .then((response) => {
      const blob = new Blob([response.data], {type: "application/csv"})
      saveAs(blob, 'documentos.csv')
      this.$dialog.message.info('La descarga se ha realizado con éxito.')
    })
    .catch(() => {
      this.$dialog.message.error('Ha ocurrido un error en el proceso de descarga')
    })
    .finally(() => {
      this.reset += 1
    })
  }
 }
}
</script>
<style>
.tab-rec.v-tabs .v-item-group.v-slide-group:not(.tab-rec) {
  border-bottom: none;
}
</style>